<template>
  <div>
    <div class="content-item" style="margin-top: 0px">
      <div class="freight-item van-hairline--bottom">
        <span class="freight-item-title">{{ $t("Transportation type") }}</span>
        <span>{{
          freightOrderExtra && freightOrderExtra.expressWay
            ? freightOrderExtra.expressWay == "2"
              ? $t("海运")
              : $t("陆运")
            : "--"
        }}</span>
      </div>
      <div class="freight-item van-hairline--bottom">
        <span class="freight-item-title">{{ $t("Total Weight") }}</span>
        <span>{{
          freightOrderExtra && freightOrderExtra.totalWeight
            ? `${freightOrderExtra.totalWeight} KG`
            : "--"
        }}</span>
      </div>
      <div class="freight-item van-hairline--bottom">
        <span class="freight-item-title">{{ $t("Total Volume") }}</span>
        <span
          >{{
            freightOrderExtra && freightOrderExtra.totalVolume
              ? `${freightOrderExtra.totalVolume} CBM`
              : "--"
          }}
        </span>
      </div>
      <div class="freight-item">
        <span class="freight-item-title">{{ $t("Amount") }}</span>
        <span>{{
          freightOrder && freightOrder.totalForeignFee
            ? `฿${freightOrder.totalForeignFee}`
            : "--"
        }}</span>
      </div>
      <div class="freight-item-amount">
        <span class="freight-item-title">{{ $t("Cross-border") }}(฿45/KG)</span>
        <span style="color: #555">{{
          freightOrder && freightOrder.firstLegFee
            ? `฿${freightOrder.firstLegFee}`
            : "--"
        }}</span>
      </div>
      <div class="freight-item-amount van-hairline--bottom">
        <span class="freight-item-title">{{ $t("Deliver to door") }}</span>
        <span style="color: #555">{{
          freightOrder && freightOrder.secondLegFee
            ? `฿${freightOrder.secondLegFee}`
            : "--"
        }}</span>
      </div>
      <div
        class="freight-item van-hairline--top"
        v-if="freightOrder && freightOrder.cashCouponFee"
      >
        <span class="freight-item-title">{{ $t("Discount") }}</span>
        <span>{{
          freightOrder && freightOrder.cashCouponFee
            ? `฿${freightOrder.cashCouponFee}`
            : "--"
        }}</span>
      </div>
    </div>

    <div class="content-item">
      <div style="font-weight: bold; padding: 10px 15px; font-size: 1.1rem">
        {{ $t("Orders") }}
      </div>
      <div
        style="padding: 0px 15px"
        v-if="shopifyOrders && shopifyOrders.length"
      >
        {{
          $t("orders have been shipped together", {
            amount: shopifyOrders.length,
          })
        }}
      </div>
      <div
        class="freight-item van-hairline--bottom"
        style="padding: 10px 15px"
        v-for="shopify in shopifyOrders"
        @click="toOrderDetail(shopify.shopifyOrderId)"
        :key="shopify.id"
      >
        <span class="freight-item-title">{{ shopify.shopifyOrderName }}</span>
        <div style="text-align: right">
          <div>
            {{ shopify.totalWeight ? `${shopify.totalWeight}KG` : "--" }}
          </div>
          <div>
            {{ shopify.totalVolume ? `${shopify.totalVolume}CBM` : "--" }}
          </div>
        </div>
        <van-icon name="arrow" color="#555" />
      </div>
    </div>

    <div class="content-item" v-if="waybills && waybills.length !== 0">
      <div style="font-weight: bold; padding: 10px 15px; font-size: 1.1rem">
        {{ $t("Waybills") }}
      </div>
      <div style="padding: 0px 15px">
        {{ $t("Total packages", { amount: waybills.length }) }}
      </div>
      <div
        class="freight-item van-hairline--bottom"
        style="padding: 10px 15px"
        v-for="bill in waybills"
        :key="bill.id"
        @click="toTracking(bill.id)"
      >
        <span class="freight-item-title"
          >{{ bill.fTrackingNo }} ({{ bill.fExpressCompany }})</span
        >
        <van-icon name="arrow" color="#555" />
      </div>
    </div>
  </div>
</template>

<script>
import { Toast, Icon } from "vant";
import { shippingDetails } from "./service";

export default {
  components: {
    [Icon.name]: Icon,
  },
  data() {
    return {
      shopifyOrderId: this.$route.query.shopifyOrderId,
      waybills: null,
      freightOrder: null,
      shopifyOrders: null,
      freightOrderExtra: null,
    };
  },
  methods: {
    toTracking(id) {
      this.$router.push({
        name: "tracking",
        query: {
          id,
        },
      });
    },
    toOrderDetail(id) {
      this.$router.push({
        name: "orderDetail",
        query: { id },
      });
    },
    async shippingDetails() {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("加载中..."),
      });

      const { success, data, message } = await shippingDetails(
        this.shopifyOrderId
      );

      Toast.clear();
      if (success) {
        const { waybills, freightOrder, shopifyOrders, freightOrderExtra } =
          data;

        this.waybills = waybills;
        this.freightOrder = freightOrder;
        this.shopifyOrders = shopifyOrders;
        this.freightOrderExtra = freightOrderExtra;
      } else Toast.fail(message);
    },
  },
  mounted() {
    // this.shopifyOrderId = "gid://shopify/Order/4939758829810";
    this.shippingDetails();
  },
};
</script>

<style lang="less" scoped>
.content-item {
  background-color: white;
  margin-top: 10px;
  font-size: 0.9rem;
}

.freight-item {
  display: flex;
  align-items: center;
  padding: 10px 15px;
}

.freight-item-title {
  flex: 1;
}

.freight-item-amount {
  display: flex;
  height: 30px;
  align-items: center;
  line-height: 30px;
  padding: 0px 15px 0px 30px;
  color: #999;
  font-size: 0.9rem;
}
</style>